import React, { Component } from "react"
import { Link } from "gatsby"
import ReactGA from "react-ga"
import CookieConsent from "react-cookie-consent"

class GA extends Component {
  initGA = () => {
    ReactGA.initialize(this.props.code, { anonymizeIp: true })
    ReactGA.pageview(this.props.page)
  }

  render() {
    var gdprRequired = true
    return (
      <>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-866N5JBZLB"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-866N5JBZLB');`,
          }}
        ></script>
        {gdprRequired ? (
          <div className="cookieConsent">
            <CookieConsent
              disableStyles
              buttonClasses="cookie-btn"
              buttonText="Accept"
              onAccept={() => {
                this.initGA()
              }}
            >
              <p className="cookie-text">
                We use cookies to ensure that we give you the best experience on
                our website.
                <Link to="/privacy" style={{ textDecoration: "underline" }}>
                  Privacy Policy
                </Link>
              </p>
            </CookieConsent>
          </div>
        ) : (
          this.initGA()
        )}
      </>
    )
  }
}
export default GA
